import React, { useState } from 'react';
import Header from '../Header/Header'
import Footer from "../Footer/Footer"
import { useHistory } from 'react-router-dom';
import { Transition } from "@headlessui/react";
import LOGO from "./img/logo.png"

/**
 * This represents the main layout of the file. Including header, body, and footer 
 */
const Layout =({
        isSignedIn,
        user,
        children}) =>{

    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);

    return(
        <div class="flex flex-col white min">
                <div class="flex justify-end mx-4 my-4">
                    <div className="mr-2 flex md:hidden">
                            <button
                              onClick={() => setIsOpen(!isOpen)}
                              type="button"
                              className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                              aria-controls="mobile-menu"
                              aria-expanded="false"
                            >
                              <span className="sr-only">Open main menu</span>
                              {!isOpen ? (
                                <svg
                                  className="block h-6 w-6"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  className="block h-6 w-6"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                  />
                                </svg>
                              )}
                          </button>
                    </div>
                </div>    

            <div class="flex jusitfy-center items-center">  

                <p onClick={() => history.push("/")} class="poppy text-black font-bold text-2xl mx-4 my-0 cursor-pointer">
                    <div class="flex flex-col justify-center items-center">  
                        <img height="150px" width="150px" class="floating" src={LOGO}/>                                                                                             
                        <p class="text-yellow-500">CoinBuzz</p>                            
                    </div>
                </p>      

                                             

                <div class="hidden md:block lg:flex lg:justify-end lg:flex-row lg:space-x-24">
                    <p onClick={() => history.push("/add-coin")}  class="poppy text-black font-bold text-md cursor-pointer">
                           {"Add Coin"}
                       </p>                               
                       <p onClick={() => history.push("/promote")} class="poppy text-black font-bold text-md cursor-pointer">
                           {"Promote"}
                       </p>           
                       <p onClick={() => history.push("/login")} class="poppy text-black font-bold text-md cursor-pointer">
                           {
                               isSignedIn
                               ?
                               user.email
                               :
                               "Login"
                           }
                    </p>  
                </div>    

                <Transition
                 show={isOpen}
                 enter="transition ease-out duration-100 transform"
                 enterFrom="opacity-0 scale-95"
                 enterTo="opacity-100 scale-100"
                 leave="transition ease-in duration-75 transform"
                 leaveFrom="opacity-100 scale-100"
                 leaveTo="opacity-0 scale-95">
                 {(ref) => {
                     return(
                         <div>
                                 <p onClick={() => history.push("/add-coin")}  class="poppy text-black font-bold text-md cursor-pointer">
                                        {"Add Coin"}
                                    </p>                               
                                    <p onClick={() => history.push("/promote")} class="poppy text-black font-bold text-md cursor-pointer">
                                        {"Promote"}
                                    </p>           
                                    <p onClick={() => history.push("/login")} class="poppy text-black font-bold text-md cursor-pointer">
                                        {
                                            isSignedIn
                                            ?
                                            user.email
                                            :
                                            "Login"
                                        }
                                    </p>  
                         </div>
                     )  
                 }}
                </Transition>              
                                                                  
            </div>                  

            <div class="white min-min-h-screen my-8">{children}</div>
            
            <div class="flex justify-end ">
                <p class="poppy text-black font-bold my-4 text-2xl mb-4">
                        <div class="flex flex-row space-x-2 mr-4">
                          <p class="text-xl text-yellow-500">Coinbuzz </p>
                          <p class="text-sm">2021 TM</p>
                        </div>                        
                </p>
                
            </div>
        </div>
    )
}

export default Layout;