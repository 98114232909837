export const findDay = (date) => {
    
    let dateToCheck = new Date(date);
    
    const today = new Date();
    const yesterday = new Date(today);
    const oneDayBeforeYesterday = new Date(yesterday);
    const twoDayBeforeYesterday = new Date(yesterday);
    const threeDayBeforeYesterday = new Date(yesterday);
    const fourDayBeforeYesterday = new Date(yesterday);
    const fiveDayBeforeYesterday = new Date(yesterday);
  
    yesterday.setDate(yesterday.getDate() - 1);
    oneDayBeforeYesterday.setDate(yesterday.getDate() - 1);
    twoDayBeforeYesterday.setDate(yesterday.getDate() - 2);
    threeDayBeforeYesterday.setDate(yesterday.getDate() - 3);
    fourDayBeforeYesterday.setDate(yesterday.getDate() - 4);
    fiveDayBeforeYesterday.setDate(yesterday.getDate() - 5);
  
    let days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];
    if (dateToCheck.toDateString() === today.toDateString()) {
        return "Today";
    } else if (dateToCheck.toDateString() === oneDayBeforeYesterday.toDateString()) {
        return days[dateToCheck.getDay()];
    } else if (dateToCheck.toDateString() === twoDayBeforeYesterday.toDateString()) {
        return days[dateToCheck.getDay()];
    } else if (dateToCheck.toDateString() === threeDayBeforeYesterday.toDateString()) {
        return days[dateToCheck.getDay()];
    } else if (dateToCheck.toDateString() === fourDayBeforeYesterday.toDateString()) {
        return days[dateToCheck.getDay()];
    } else if (dateToCheck.toDateString() === fiveDayBeforeYesterday.toDateString()) {
        return days[dateToCheck.getDay()];
    } else  {
        return "Today";
    } 
  }