import React, { useState, useEffect, useRef } from 'react'
import { createClient } from '@supabase/supabase-js'
import styled from 'styled-components'
import { slice, concat } from 'lodash'
import _ from 'lodash'
import publicIp from 'public-ip'
import DataTable from '../components/Table/Table'
import Leaderboard from '../components/Table/LeaderTable'
import 'react-responsive-modal/styles.css'
import { useStore } from '../store'
import SkeletonCard from '../components/SkeletonCard/SkeletonCard'
import PromotionsTable from '../components/Table/PromotionsTable'
import { findDay } from '../utils/utils'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY
const supabase = createClient(supabaseUrl, supabaseKey)

const Card = styled.button`
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 1px solid black;
  border-radius: 5px;
`

const BlackCard = styled(Card)`
  background-color: white;
  font-color: black;
`

const Dashboard = () => {
  let buttonRef = useRef(null)

  const [user_id, setUserId] = useState('')
  const [loading, setLoading] = useState(true)
  const state = useStore()
  const LIMIT = 4

  //initial load
  useEffect(() => {
    async function getIpAddress () {
      const ipAddress = await publicIp.v4({
        fallbackUrls: ['https://ifconfig.co/ip']
      })
      setUserId(ipAddress)
    }
    getIpAddress()
    let coinCopy
    async function fetchData () {
      //Fetch coins
      let { data: coins, error } = await supabase.from('coins').select('*')
      if (error) {
        console.log('ERROR IS', error)
      }
      //Fetch promoted coins
      let { data: promotedCoins, e } = await supabase.from('promoted_coins')
        .select(`
              coins_id,
              coins (
                *
              )
            `)
      if (e) {
        console.log('ERROR WHEN FETCHING PROMOTIONS', e)
      }
      if (promotedCoins) {
        let promotionsArr = []
        let trackPromotions = []
        let shuffled = shuffle(promotedCoins) //Shuffle priority of the promotions based on where they appear
        for (let i = 0; i < shuffled.length; i++) {
          promotionsArr.push(shuffled[i].coins)
          trackPromotions.push(shuffled[i].coins.id)
        }
        state.setPromotedCoins(promotionsArr)
        state.setTrackPromotions(trackPromotions)
      }
      //We bias towards coins with the most upvotes
      coins.sort((a, b) =>
        a.upvoted_users.length > b.upvoted_users.length
          ? -1
          : a.upvoted_users.length < b.upvoted_users.length
          ? 1
          : 0
      )
      coinCopy = coins
      //RESET SHOW MORE BUTTON
      let sorted = {}
      let viewLimits = {}
      let sortedView = {}
      let sortedCpy = {} //store previous state of coins
      coins.forEach(coin => {
        let date = coin.date_added
        date = formatDate(date)
        if (!sorted[date]) {
          //This would cointain all coins added on a specific date
          sorted[date] = []
          sortedCpy[date] = []
          viewLimits[date] = {} //initialize viewing limiit hashmap
          viewLimits[date]['VIEWS_LIMIT'] = 4 //initialize viewing limit number
          //Current index of the specific date in iteration
          viewLimits[date]['COIN_INDEX'] = 4
          viewLimits[date]['SHOW_MORE'] = true
          sorted[date].push(coin)
          sortedCpy[date].push(coin)
        } else {
          sorted[date].push(coin)
          sortedCpy[date].push(coin)
        }
      })

      sortedView = sorted

      Object.keys(sorted).forEach((date, _) => {
        let slicedData = sorted[date].slice(0, LIMIT) //Partition first N objects to view
        sortedView[date] = slicedData
      })

      //SORT BY DATE, THEN UPDATE
      state.setCoinsView(sortedView)
      state.setSortedCoins(sortedCpy)
      state.setLeaderboard(coinCopy)
      state.setViewLimits(viewLimits)
    }
    fetchData()
    setLoading(false)
  }, [])

  //Config for our emojis (WIP..)
  const config = {}

  //Shuffling algorithm
  const shuffle = array => {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }

  const formatDate = dateAsString => {
    let date = new Date(dateAsString)
    date = date.toISOString().split('T')[0]
    return date
  }

  //This is called whenever you make a call to loadMore()
  const loadMore = loadDate => {
    Object.keys(state.sortedCoins).forEach((date, _) => {
      date = formatDate(date)
      if (date === loadDate) {
        //Make sure to expand the correct list
        const coins = state.sortedCoins[date] //coins at this date
        let coinsView = state.coinsView //view at this date
        let viewLimits = state.viewLimits
        let viewLimitsCpy = state.viewLimits[date] //Handle how many coins are rendered on a specific date
        let currIndex = viewLimitsCpy['COIN_INDEX']
        const newIndex = viewLimitsCpy['COIN_INDEX'] + LIMIT //change limit to view Limit. E.g, first we display 4, now we display 8. If we have 16 items, we load the first 4, then  8, then 12.....
        viewLimitsCpy['COIN_INDEX'] = newIndex
        const newShowMore = newIndex < coins.length - 1 // If the list is fully rendered, no need for the show more button to appear
        //add to the list based on the current index of the main coins array
        const newList = concat(
          coinsView[date],
          slice(state.sortedCoins[date], currIndex, newIndex)
        )
        coinsView[date] = newList

        viewLimits[date] = viewLimitsCpy
        viewLimits[date]['SHOW_MORE'] = newShowMore
        state.setViewLimits(viewLimits)
        state.setCoinsView(coinsView)
      }
    })
  }

  const handleUpvote = async (newUpvotes, coinID) => {
    const { error } = await supabase
      .from('coins')
      .update({ upvoted_users: newUpvotes })
      .eq('id', coinID)
    if (error) {
      alert('Error when handling upvote')
      console.log('UPVOTE ERROR', error)
    }
  }

  const onUpvotePromotions = async (item_id, user_id, _) => {
    //Create copy of the current object to update
    let sortedCpy = state.promotedCoins
    //make upvotes the length of upvoted users
    //Update the object
    //Update promotions count
    let rowToUpdate = 0
    let newUpdate = []
    for (let i = 0; i < sortedCpy.length; i++) {
      let item = sortedCpy[i]
      if (item_id === item.id) {
        rowToUpdate = i
        const idx = item.upvoted_users.indexOf(user_id)
        if (idx > -1) {
          item.upvoted_users.splice(idx, 1) // Replace 1 element at index idx
          //Push to DB
          await handleUpvote(item.upvoted_users, item.id)
        } else {
          item.upvoted_users.push(user_id)
          //Push to DB
          await handleUpvote(item.upvoted_users, item.id)
        }
        newUpdate = item
      }
    }
    state.setPromotedCoins(sortedCpy)
  }

  const onUpvote = async (item_id, _, user_id, currentDate) => {
    //Create copy of the current object to update
    let sortedCpy = state.sortedCoins
    let currDate = formatDate(currentDate)
    //make upvotes the length of upvoted users
    //Update the object
    Object.keys(sortedCpy).forEach(date => {
      if (date === currDate) {
        sortedCpy[date].forEach(async item => {
          if (item.id === item_id) {
            const idx = item.upvoted_users.indexOf(user_id)
            if (idx > -1) {
              item.upvoted_users.splice(idx, 1) //Here we downvote if the user already upvoted
              //Push to DB
              await handleUpvote(item.upvoted_users, item.id)
            } else {
              item.upvoted_users.push(user_id) //Else do an upvote operation
              //Push to DB
              await handleUpvote(item.upvoted_users, item.id)
            }
          }
        })
      }
    })

    //Update new state with the copied object
    state.setSortedCoins(sortedCpy)

    //regenerate coinsView using the indices of viewLimits object
    let sorted = state.sortedCoins
    let sortedView = state.coinsView

    Object.keys(sorted).forEach((date, _) => {
      let viewLimits = state.viewLimits[date]
      let slicedData = sorted[date].slice(0, viewLimits['COIN_INDEX']) //maintain current expansion
      sortedView[date] = slicedData
    })

    state.setCoinsView(sortedView)
  }

  if (loading) {
    return <SkeletonCard />
  }
  return (
    <div class='flex flex-col items-center min-w-screen'>
      <p class='poppy text-black font-bold my-4 text-3xl flex-row'>
        Buzzzin' Coins
      </p>

      <p class='move'>🐝</p>

      <div
        class='
                        min-w-screen
                        lg:flex lg:justify-center lg:items-center lg:flex lg:flex-row
                        md:justify-center md:items-center md:flex md:flex-row
                        sm:justify-center sm:items-center sm:flex sm:flex-col
                        superSmall:justify-center superSmall:items-center superSmall:flex superSmall:flex-col '
      >
        {state.promotedCoins && state.promotedCoins.length > 0 && (
          <div>
            <PromotionsTable
              data={state.promotedCoins.slice(0, 5)}
              user_id={user_id}
              onUpvote={onUpvotePromotions}
              buttonRef={buttonRef}
              config={config}
              loadMore={loadMore}
              showMore={state.showMore}
            />
          </div>
        )}
      </div>

      <BlackCard>
        <p class='poppy text-black font-bold mb-4'>Upcoming launches</p>
        {
          <div class='flex flex-row space-x-4'>
            <p class='poppy text-black'>No upcoming launches</p>
          </div>
        }
      </BlackCard>
      <div
        class='lg:flex lg:flex-col lg:justify-center lg:items-center  lg:h-1/2 lg:mb-4 lg:space-y-4
                            md:col-span-1
                            sm:flex  sm:mx-8 sm:flex-col sm:space-y-4  sm:justify-center      
                            superSmall:mx-48  superSmall:space-y-4    superSmall:justify-center 
                              '
      >
        <p
          class='poppy text-black text-yellow-500 font-bold mb-4 text-xl
                              sm:mx-8 sm:my-4
                              superSmall:mx-8 superSmall:my-4'
        >
          Leaderboard
        </p>
        {state.leaderboard.length > 0 && (
          <div>
            <Leaderboard
              data={state.leaderboard.slice(0, 5)}
              user_id={user_id}
              onUpvote={onUpvote}
              buttonRef={buttonRef}
              config={config}
              loadMore={loadMore}
              showMore={state.showMore}
            />
          </div>
        )}
      </div>
      <div
        class='lg:flex lg:flex-col lg:justify-center lg:items-center  lg:h-1/2 lg:mb-4 lg:space-y-4
                            md:col-span-1
                            sm:flex  sm:mx-8 sm:flex-col sm:space-y-4  sm:justify-center      
                            superSmall:mx-48  superSmall:space-y-4    superSmall:justify-center 
                              '
      >
        <p
          class='poppy text-black font-bold mb-4 text-xl
                              sm:mx-12 sm:my-4
                              superSmall:mx-12 superSmall:my-4 text-yellow-500'
        >
          Recent launches
        </p>
        {Object.keys(state.viewLimits).length > 0 &&
          state.coinsView &&
          Object.keys(state.coinsView)
            .reverse()
            .map((date, _) => {
              let data = state.coinsView[date]
              return (
                <div>
                  <DataTable
                    data={data}
                    user_id={user_id}
                    onUpvote={onUpvote}
                    buttonRef={buttonRef}
                    config={config}
                    loadMore={loadMore}
                    showMore={state.showMore}
                    dateAdded={date}
                    date={date}
                  />
                </div>
              )
            })}
      </div>
    </div>
  )
}

export default Dashboard
