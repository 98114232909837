import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import MuiTableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { GreenCircle, RedCircle } from '../Circles/Circle'
import Reward from 'react-rewards'
import { Button } from '../Buttons/Buttons'
import { motion } from 'framer-motion'
import { FaArrowCircleUp } from 'react-icons/fa'
import { useStore } from '../../store'
import { useHistory } from 'react-router-dom'

/**
 * This component handles the upvote button for the leaderboard. 
 */

const LeaderBoardBTN = ({
  item,
  buttonRef,
  config,
  onUpvote,
  user_id,
  k,
  date
}) => {
  const state = useStore()

  return (
    <Reward
      ref={ref => {
        buttonRef = ref
      }}
      type='emoji'
      config={config}
    >
      <motion.div className='container' whileHover={{ scale: 1.3 }}>
        {/**
         * User id is an ip address. This is done to limit vote spam
         */}
        <Button
          upvoted={item.upvoted_users.indexOf(user_id) > -1}
          onClick={() => onUpvote(item.id, k, user_id, date)}
        >
          <div class='flex flex-col'>
            <div>
              <FaArrowCircleUp />
            </div>
            <div>{item.upvoted_users.length}</div>
          </div>
        </Button>
      </motion.div>
    </Reward>
  )
}

const TableCell = withStyles({
  root: {
    borderLeft: 'none',
    borderRight: 'none',
    backgroundColor: '#f0f472',
    borderColor: '#32373e',
    color: 'black'
  }
})(MuiTableCell)

const StickyTableCell = withStyles(theme => ({
  head: {
    backgroundColor: 'black',
    color: 'white',
    left: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 2
  },
  body: {
    backgroundColor: 'black',
    color: 'white',
    minWidth: '50px',
    left: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 1
  }
}))(TableCell)

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  tableSm: {
    minWidth: 650
  }
}))

/**
 * Handle top voted coins
 */

export default function LeaderBoard ({
  data,
  user_id,
  buttonRef,
  config,
  onUpvote,
  date
}) {
  const state = useStore()
  const history = useHistory()

  return (
    <div>
      {/** Handle (Hide leaderboard on) small screens. Hide for lg and md screen sizes */}
      <div
        className='mx-4
    my-8
    lg:hidden
    md:hidden
    space-y-2'
      >
        <TableContainer>
          <Table class='cursor-pointer'>
            <TableHead>
              <TableRow>
                <StickyTableCell>
                  <p class='poppy text-gray-400'>Name</p>
                </StickyTableCell>
                <TableCell align='right'>
                  <p class='poppy text-gray-400 uppercase'>Symbol</p>
                </TableCell>
                <TableCell align='right'>
                  <p class='poppy text-gray-400 uppercase'>Upvotes</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, k) => (
                <TableRow key={row.id}>
                  <StickyTableCell component='th' scope='row'>
                    <div class='flex flex-col justify-center mx-2'>
                      <motion.div
                        whileHover={{ scale: 1.2, rotate: 45 }}
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{
                          type: 'spring',
                          stiffness: 260,
                          damping: 20
                        }}
                      >
                        <img height='50px' width='50px' src={row.logo} />
                      </motion.div>
                      <div class='sticky-col'>{row.coin_name.slice(0, 8)}</div>
                    </div>
                  </StickyTableCell>

                  <TableCell
                    align='right'
                    onClick={() =>
                      history.push({
                        pathname: `/coin/${row.id}`,
                        state: { modalData: row, date: date }
                      })
                    }
                  >
                    <div class='flex items-center justify-center'>
                      {row.coin_symbol}
                    </div>
                  </TableCell>

                  <TableCell align='right'>
                    <div class='flex items-center justify-center'>
                      <LeaderBoardBTN
                        buttonRef={buttonRef}
                        item={row}
                        config={config}
                        onUpvote={onUpvote}
                        user_id={user_id}
                        k={k}
                        date={row.date_added}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/** Display for larger screens */}

      <div className='hidden md:block xs:hidden sm:hidden mx-8'>
        <TableContainer>
          <Table class='cursor-pointer'>
            <TableHead>
              <TableRow>
                <StickyTableCell>
                  <p class='poppy text-gray-400'>Name</p>
                </StickyTableCell>
                <TableCell align='right'>
                  <p class='poppy text-gray-400 uppercase'>Audit</p>
                </TableCell>
                <TableCell align='right'>
                  <p class='poppy text-gray-400 uppercase'>Rug score</p>
                </TableCell>
                <TableCell align='right'>
                  <p class='poppy text-gray-400 uppercase'>Doxxed Team</p>
                </TableCell>
                <TableCell align='right'>
                  <p class='poppy text-gray-400 uppercase'>Comments</p>
                </TableCell>
                <TableCell align='right'>
                  <p class='poppy text-gray-400 uppercase'>Chain</p>
                </TableCell>
                <TableCell align='right'>
                  <p class='poppy text-gray-400 uppercase'>Symbol</p>
                </TableCell>
                <TableCell align='right'>
                  <p class='poppy text-gray-400 uppercase'>Upvotes</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, k) => (
                <TableRow key={row.id}>
                  <StickyTableCell component='th' scope='row'>
                    <div class='flex flex-col justify-center mx-2'>
                      <motion.div
                        whileHover={{ scale: 1.2, rotate: 45 }}
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{
                          type: 'spring',
                          stiffness: 260,
                          damping: 20
                        }}
                      >
                        <img height='50px' width='50px' src={row.logo} />
                      </motion.div>
                      <div class='sticky-col'>{row.coin_name.slice(0, 8)}</div>
                    </div>
                  </StickyTableCell>
                  <TableCell
                    align='right'
                    onClick={() =>
                      history.push({
                        pathname: `/coin/${row.id}`,
                        state: { modalData: row, date: date }
                      })
                    }
                  >
                    <div class='flex items-center justify-center'>
                      {row.audit ? <GreenCircle /> : <RedCircle />}
                    </div>
                  </TableCell>
                  <TableCell
                    align='right'
                    onClick={() =>
                      history.push({
                        pathname: `/coin/${row.id}`,
                        state: { modalData: row, date: date }
                      })
                    }
                  >
                    0
                  </TableCell>
                  <TableCell
                    align='right'
                    onClick={() =>
                      history.push({
                        pathname: `/coin/${row.id}`,
                        state: { modalData: row, date: date }
                      })
                    }
                  >
                    <div class='flex items-center justify-center'>
                      {row.doxxed ? <GreenCircle /> : <RedCircle />}
                    </div>
                  </TableCell>
                  <TableCell
                    align='right'
                    onClick={() =>
                      history.push({
                        pathname: `/coin/${row.id}`,
                        state: { modalData: row, date: date }
                      })
                    }
                  >
                    <div class='flex items-center justify-center'>
                      {row.comments.length}
                    </div>
                  </TableCell>
                  <TableCell
                    align='right'
                    onClick={() =>
                      history.push({
                        pathname: `/coin/${row.id}`,
                        state: { modalData: row, date: date }
                      })
                    }
                  >
                    <div class='flex items-center justify-center'>
                      {row.chain}
                    </div>
                  </TableCell>
                  <TableCell
                    align='right'
                    onClick={() =>
                      history.push({
                        pathname: `/coin/${row.id}`,
                        state: { modalData: row, date: date }
                      })
                    }
                  >
                    <div class='flex items-center justify-center'>
                      {row.coin_symbol}
                    </div>
                  </TableCell>
                  <TableCell align='right'>
                    <div class='flex items-center justify-center'>
                      <LeaderBoardBTN
                        buttonRef={buttonRef}
                        item={row}
                        config={config}
                        onUpvote={onUpvote}
                        user_id={user_id}
                        k={k}
                        date={row.date_added}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}
